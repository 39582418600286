import { FormControl } from "@angular/forms";
import { FormValidator } from "./form-validator";

export class RegexValidator implements FormValidator {

    constructor(private _pattern: string | RegExp) {

    }

    logic = (control: FormControl): boolean => {
        if (control.value) {
            return control.value.match(this._pattern);
        } else {
            return true;
        }
    }
}